/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import { BOOKMARKS, CART } from 'Features/SwitchableFeature/SwitchableFeature.definitions';

import Avatar from '../Avatar/Avatar';
import SocialButtons from '../SocialButtons/SocialButtons';
import Button from '../Button/Button';
import DrawerToMenu, { DrawerContent, MenuContent } from '../DrawerToMenu/DrawerToMenu';

import { ReactComponent as ProfileOverviewIcon } from '../../images/icons/account-information.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as NewsletterIcon } from '../../images/icons/newsletter-settings.svg';
import { ReactComponent as WishlistIcon } from '../../images/icons/wishlist.svg';
import { ReactComponent as BookmarkIcon } from '../../images/icons/bookmark.svg';
import { ReactComponent as RecentIcon } from '../../images/icons/recently-viewed.svg';
import { ReactComponent as OrderListDefault } from '../../images/icons/order-list_default.svg';
import { ReactComponent as LocationIcon } from '../../images/icons/location.svg';

import { useAuth0Wrapper } from '../../utils/auth0';

import styles from './ProfilePopup.module.scss';
import { getPagePath, getPopupOnPagePath } from '../../utils/appRoutes';

import {
  ARD_PROFILE_PERSONAL_INFO,
  ARD_POPUP_PROFILE_PERSONAL_INFO,
  ARD_PROFILE_WISHLIST,
  ARD_PROFILE_ORDERS_N_RETURNS,
  ARD_PROFILE_BOOKMARKS,
  ARD_PROFILE_RECENT_PRODUCTS,
  ARD_POPUP_PROFILE_WISHLIST,
  ARD_POPUP_PROFILE_ORDERS,
  ARD_POPUP_PROFILE_BOOKMARKS,
  ARD_POPUP_PROFILE_RECENT_PRODUCTS,
  ARD_PROFILE_NEWSLETTER,
  ARD_POPUP_PROFILE_NEWSLETTER,
  ARD_PROFILE_ADDRESSES,
  ARD_PRIVACY,
} from '../../utils/appRoutes.definitions';
import useWishlist from '../../utils/useWishlist';
import { setTriggerLogout } from '../../state/profile/profile';

const AnonymousUserContent = () => {
  return (
    <>
      <header>
        <FormattedMessage id="popups.login.title" defaultMessage="Log in or signup" />
      </header>
      <div className={styles.privacyAgreement}>
        <FormattedHTMLMessage
          id="profile.privacyAgreement"
          defaultMessage="By continuing, you agree to our privacy agreement"
          values={{ link: getPagePath(ARD_PRIVACY) }}
        />
      </div>
      <SocialButtons />
    </>
  );
};

const ARD_PATH_TO_POPUP = {
  [ARD_PROFILE_PERSONAL_INFO]: ARD_POPUP_PROFILE_PERSONAL_INFO,
  [ARD_PROFILE_WISHLIST]: ARD_POPUP_PROFILE_WISHLIST,
  [ARD_PROFILE_ORDERS_N_RETURNS]: ARD_POPUP_PROFILE_ORDERS,
  [ARD_PROFILE_BOOKMARKS]: ARD_POPUP_PROFILE_BOOKMARKS,
  [ARD_PROFILE_RECENT_PRODUCTS]: ARD_POPUP_PROFILE_RECENT_PRODUCTS,
  [ARD_PROFILE_NEWSLETTER]: ARD_POPUP_PROFILE_NEWSLETTER,
};

const pickTargetUrl = (asPopup, pageName) => {
  if (asPopup) return getPopupOnPagePath(ARD_PATH_TO_POPUP[pageName]);
  else return getPagePath(pageName);
};

const AuthorizedUserContent = ({ asPopup, profile }) => {
  const { logoutWithRedirect } = useAuth0Wrapper();
  const { wishlist } = useWishlist();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const notShowLogoutModal = JSON.parse(localStorage.getItem('notShowLogoutModal'));
    if (notShowLogoutModal === true) {
      logoutWithRedirect();
      return;
    }
    dispatch(setTriggerLogout(true));
    push({ ...location, hash: '' });
  };

  return (
    <>
      <div className={styles.user}>
        {profile && <Avatar src={profile.picture} salutation={profile.salutation} />}
        <h3>{profile?.given_name + ' ' + profile?.family_name}</h3>
      </div>
      <div className={styles.menuItems}>
        <Link to={pickTargetUrl(asPopup, ARD_PROFILE_PERSONAL_INFO)} className={styles.myProfile}>
          <ProfileOverviewIcon />
          <FormattedMessage id="profile.myProfile" defaultMessage="My profile" />
        </Link>
        <Link to={pickTargetUrl(asPopup, ARD_PROFILE_NEWSLETTER)}>
          <NewsletterIcon />
          <FormattedMessage id="profile.newsletterSection" defaultMessage="Newsletter settings" />
        </Link>
        <SwitchableFeature feature={CART}>
          <Link to={getPagePath(ARD_PROFILE_ORDERS_N_RETURNS)}>
            <OrderListDefault height="1em" width="1em" />
            <FormattedMessage id="orders.listingName" defaultMessage="Orders and returns list" />
          </Link>
          <Link to={getPagePath(ARD_PROFILE_ADDRESSES)}>
            <LocationIcon height="1em" width="1em" />
            <FormattedMessage id="profile.addresses.pageHeader" defaultMessage="My addresses" />
          </Link>
        </SwitchableFeature>
        <Link to={pickTargetUrl(asPopup, ARD_PROFILE_WISHLIST)} className={styles.myWishlist}>
          <WishlistIcon />
          <FormattedMessage id="profile.wishlist" defaultMessage="Wishlist" />
          {!!wishlist?.length && <span>{wishlist.length}</span>}
        </Link>
        <SwitchableFeature feature={BOOKMARKS}>
          <Link to={pickTargetUrl(asPopup, ARD_PROFILE_BOOKMARKS)} className={styles.myBookmarks}>
            <BookmarkIcon />
            <FormattedMessage id="profile.bookmark" defaultMessage="Bookmarks" />
            {profile.bookmarkedBlogs?.length > 0 && <span>{profile.bookmarkedBlogs.length}</span>}
          </Link>
        </SwitchableFeature>

        <Link to={pickTargetUrl(asPopup, ARD_PROFILE_RECENT_PRODUCTS)} className={styles.myRecentlyViewed}>
          <RecentIcon />
          <FormattedMessage id="profile.recentProducts" defaultMessage="Recent products" />
          {profile.recentlyViewedProducts?.length > 0 && <span>{profile.recentlyViewedProducts.length}</span>}
        </Link>
      </div>
      <Button classList={{ root: styles.button }} inverted onClick={handleLogout}>
        <FormattedMessage id="common.logOut" defaultMessage="Log out" />
      </Button>
    </>
  );
};

const ProfilePopup = React.memo(function ProfilePopup({ config, profile, shouldBeOpen }) {
  const location = useLocation();

  const { push } = useHistory();

  return (
    <>
      <DrawerToMenu>
        <DrawerContent
          direction="RightToLeft"
          trigger={`${config.cmsSlugs.profile}`}
          onOverlayClick={() => push({ ...location, hash: ' ' })}
        >
          <div className="container">
            <div className="row">
              <div className={classNames(styles.profilePopup, 'col-12-xs')}>
                <header>
                  <FormattedMessage id="common.menu.closeMenu" defaultMessage="Close">
                    {(label) => (
                      <button aria-label={label} onClick={() => push({ ...location, hash: '' })}>
                        <CloseIcon />
                      </button>
                    )}
                  </FormattedMessage>
                </header>
                {!profile && <AnonymousUserContent />}
                {!!profile && <AuthorizedUserContent profile={profile} />}
              </div>
            </div>
          </div>
        </DrawerContent>
        <MenuContent
          classList={{ root: styles.root }}
          shouldBeOpen={shouldBeOpen}
          automationId={profile ? 'profile-popup' : 'login-popup'}
        >
          <div className={classNames(styles.profilePopup, { [styles.loggedIn]: profile })}>
            {!profile && <AnonymousUserContent />}
            {!!profile && <AuthorizedUserContent profile={profile} />}
          </div>
        </MenuContent>
      </DrawerToMenu>
    </>
  );
});

ProfilePopup.displayName = 'ProfilePopup';

const mapStateToProps = ({ config, profile }) => ({ config, profile });
export default connect(mapStateToProps)(ProfilePopup);
